import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { API } from "@escolalms/sdk/lib";

import { Text, Icon } from "../../../../../index";
import { useCourseAgendaContext } from "../context";
import styled from "styled-components";
import { getStylesBasedOnTheme } from "@/components/VB/utils/utils";

/*
1. CSS grid starts with 1 as closest to the edge value
2. JS Arrays are counting from 0 - first shift by 1
3. First topic with can_skip=false should be actually visible - second shift by 1
*/
const GRID_PURPOSES_NUMBER = 2;

interface Props {
	lesson: API.Lesson;
	isLessonLocked: boolean;
}

const StyledLi = styled.li`
	grid-column: 1/2;
	width: 100%;
	height: 100%;
	position: absolute;
	display: grid;
	place-items: center;
	z-index: 1;
	backdrop-filter: blur(2px);
	background: ${({ theme }) => getStylesBasedOnTheme(theme.mode, "rgb(0 0 0 / 50%)", "rgb(255 255 255 / 50%)")};
	place-content: center;
	gap: 4px;
	box-sizing: border-box;
	padding: 4px;

	&--row {
		grid-auto-flow: column;

		.lesson__overlay-text {
			margin: 0;
		}

		svg {
			width: 18px;
		}

`;

export const LockedOverlay: React.FC<Props> = ({ lesson, isLessonLocked }) => {
	const { firstBlockingTopic, positionInLessonOfFirstBlockingTopic } = useCourseAgendaContext();
	const { t } = useTranslation();

	const gridStartingPosition = positionInLessonOfFirstBlockingTopic + GRID_PURPOSES_NUMBER;
	const totalHeightOfOverlay = useMemo(() => {
		if (!Array.isArray(lesson?.topics)) return 0;

		return lesson.topics.length - positionInLessonOfFirstBlockingTopic - 1;
	}, [lesson?.topics, positionInLessonOfFirstBlockingTopic]);

	const overlayTextWhenLessonIsLocked =
		totalHeightOfOverlay > 2
			? t("CourseAgenda.FinishRequiredTopicsBefore")
			: t("CourseAgenda.FirstFinishRequiredTopics");

	const overlayTextWhenTopicIsLocked =
		totalHeightOfOverlay > 1 ? t("CourseAgenda.YouHaveToCompleteTopic") : t("CourseAgenda.TopicIsLocked");

	return (
		<StyledLi
			className={`lesson__overlay ${totalHeightOfOverlay === 1 ? "lesson__overlay--row" : ""}`}
			style={{
				gridRowStart: isLessonLocked ? 1 : gridStartingPosition,
			}}
		>
			<Icon name="lock" />
			{isLessonLocked ? (
				<>
					<span className="text-center text-sm font-medium">{overlayTextWhenLessonIsLocked}</span>
					{totalHeightOfOverlay > 2 && (
						<span className="text-center text-sm font-medium">
							{t("CourseAgenda.TopicToComplete")}:{" "}
							<span className="text-center text-sm font-medium">{firstBlockingTopic?.title}</span>
						</span>
					)}
				</>
			) : (
				<span className="text-center text-sm font-medium">
					{overlayTextWhenTopicIsLocked}
					<span className="text-center text-sm font-medium">
						{totalHeightOfOverlay < 3 &&
							totalHeightOfOverlay > 1 &&
							positionInLessonOfFirstBlockingTopic !== -1 &&
							` nr. ${positionInLessonOfFirstBlockingTopic + 1} `}
						{totalHeightOfOverlay >= 3 && ` ${firstBlockingTopic?.title} `}
					</span>
					<span className="text-center text-sm font-medium">
						{totalHeightOfOverlay > 2 && t("CourseAgenda.ToAccessTheFollowing")}
					</span>
				</span>
			)}
		</StyledLi>
	);
};
