import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import routeRoutes from "@/components/Routes/routes";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react/context";
import SearchCourses from "@escolalms/components/lib/components/organisms/SearchCourses/SearchCourses";
import { useTranslation } from "react-i18next";

import ImageBrand from "../../../images/vidaboa-brand.svg";
import { IconChevronDown, IconMenu2, IconX } from "@tabler/icons-react";
import { Avatar } from "@/components/VB";

const Navbar = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { user: userObj, logout } = useContext(EscolaLMSContext);
	const user = userObj.value;

	const [openMobileMenu, setOpenMobileMenu] = useState(false);

	useEffect(() => {
		setOpenMobileMenu(openMobileMenu);
	}, [openMobileMenu]);

	const Brand = () => {
		return (
			<Link to="/">
				<img src={ImageBrand} alt="Vidaboa" className="h-8 md:h-12" />
			</Link>
		);
	};

	const Menu = () => {
		return (
			<ul className="hidden items-center gap-8 md:flex">
				<li>
					<Link
						to={routeRoutes.home}
						className="text-lg font-semibold text-default-800 hover:text-primary-900"
					>
						Home
					</Link>
				</li>
				<li>
					<Link
						to={routeRoutes.courses}
						className="text-lg font-semibold text-default-800 hover:text-primary-900"
					>
						Cursos
					</Link>
				</li>
				<li>
					<Link
						to={routeRoutes.tutors}
						className="text-lg font-semibold text-default-800 hover:text-primary-900"
					>
						Tutores
					</Link>
				</li>
			</ul>
		);
	};

	const Search = () => {
		return (
			<>
				<div className="hidden w-[400px] md:block">
					<SearchCourses
						onItemSelected={(item) =>
							history.push(`/courses/${item.id}`)
						}
						onInputSubmitted={(input) =>
							history.push(`/courses/?title=${input}`)
						}
					/>
				</div>
			</>
		);
	};

	const Profile = () => {
		return (
			<>
				<div className="ml-auto hidden xl:block">
					{!!user?.name && (
						<>
							<div className="group relative inline-block">
								<div className="flex cursor-pointer items-center gap-2">
									<div className="flex items-center gap-2">
										<span className="text-lg font-semibold">
											{user.name}
										</span>
										{!!user?.avatar && user.name && (
											<Avatar
												src={user.avatar}
												alt={user.name}
												className="!h-8 !w-8"
											/>
										)}
									</div>
									<IconChevronDown />
								</div>
								<div className="absolute right-0 hidden group-hover:block">
									<div className="mt-4 w-[270px] rounded-lg border border-default-200 bg-default-0 p-2 shadow">
										<ul>
											<li>
												<Link
													to={routeRoutes.myProfile}
													className="block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
												>
													{t("Navbar.MyCourses")}
												</Link>
											</li>
											<li>
												<Link
													to={
														routeRoutes.myConsultations
													}
													className="block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
												>
													{t(
														"Navbar.MyConsultations",
													)}
												</Link>
											</li>
											<li>
												<Link
													to={
														routeRoutes.myNotifications
													}
													className="block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
												>
													{t(
														"MyProfilePage.Notifications",
													)}
												</Link>
											</li>
											<li>
												<Link
													to={routeRoutes.myData}
													className="block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
												>
													{t("Navbar.EditProfile")}
												</Link>
											</li>
											<hr className="my-2 border-default-100" />
											<li>
												<button
													className="w-full rounded-md px-3 py-3 text-left font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
													onClick={() =>
														logout().then(() =>
															history.push(
																routeRoutes.home,
															),
														)
													}
												>
													{t("Navbar.Logout")}
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</>
					)}
				</div>
			</>
		);
	};

	const MenuMobile = () => {
		return (
			<>
				<button
					className="block cursor-pointer xl:hidden"
					onClick={() => setOpenMobileMenu(!openMobileMenu)}
				>
					{openMobileMenu ? <IconX /> : <IconMenu2 />}
				</button>

				<div
					className={`absolute left-0 top-[65px] h-[calc(100vh-65px)] w-full bg-default-0 p-6 shadow-2xl ${
						openMobileMenu ? "block" : "hidden"
					}`}
				>
					<div className="mb-4 block md:hidden">
						<SearchCourses
							onItemSelected={(item) =>
								history.push(`/courses/${item.id}`)
							}
							onInputSubmitted={(input) =>
								history.push(`/courses/?title=${input}`)
							}
						/>
					</div>
					<ul className="flex flex-col divide-y divide-default-200">
						<li>
							<Link
								className="my-2 block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
								to={routeRoutes.home}
							>
								Home
							</Link>
						</li>
						<li>
							<Link
								className="my-2 block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
								to={routeRoutes.courses}
							>
								Cursos
							</Link>
						</li>
						<li>
							<Link
								className="my-2 block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
								to={routeRoutes.tutors}
							>
								Tutores
							</Link>
						</li>
						<li>
							<Link
								className="my-2 block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
								to={routeRoutes.myProfile}
							>
								{t("Navbar.MyCourses")}
							</Link>
						</li>
						<li>
							<Link
								className="my-2 block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
								to={routeRoutes.myConsultations}
							>
								{t("Navbar.MyConsultations")}
							</Link>
						</li>
						<li>
							<Link
								className="my-2 block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
								to={routeRoutes.myNotifications}
							>
								{t("MyProfilePage.Notifications")}
							</Link>
						</li>
						<li>
							<Link
								className="my-2 block rounded-md px-3 py-3 font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
								to={routeRoutes.myData}
							>
								{t("Navbar.EditProfile")}
							</Link>
						</li>
						<li>
							<button
								className="my-2 block w-full rounded-md px-3 py-3 text-left font-semibold text-default-800 transition-all duration-300 ease-in-out hover:bg-default-100"
								onClick={() =>
									logout().then(() =>
										history.push(routeRoutes.home),
									)
								}
							>
								{t("Navbar.Logout")}
							</button>
						</li>
					</ul>
				</div>
			</>
		);
	};

	return (
		<>
			<div className="sticky top-0 z-[999] flex h-[65px] items-center border-b border-default-200 bg-default-0 xl:h-[80px]">
				<div className="container mx-auto px-4 md:px-0">
					<div className="flex items-center justify-between gap-12 md:justify-start">
						<Brand />
						<Menu />
						<Search />
						<Profile />

						<MenuMobile />
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;
