import React, { useEffect } from "react";

import Routes from "./components/Routes";

import { createGlobalStyle } from "styled-components";
import * as Sentry from "@sentry/react";

import "./style/globals.css";
import { useLanguage } from "./hooks/useLanguage";

const GlobalStyle = createGlobalStyle`
	body > iframe {
		display: none !important;
	}
`;

const App = () => {
	const { handleLanguageChange } = useLanguage();

	useEffect(() => {
		handleLanguageChange({
			label: "Português",
			value: "ptBR",
		});
	}, []);

	return (
		<React.Fragment>
			<GlobalStyle />
			<Routes />
		</React.Fragment>
	);
};

window.addEventListener("error", (event: ErrorEvent) => {
	if (event.message.includes("Cannot read properties of undefined")) {
		if (!window.location.href.includes("noerrorrefresh")) {
			localStorage.removeItem("lms");
			window.location.href = window.location.href + "#noerrorrefresh";
		}
	}
});

export default Sentry.withProfiler(App);
