"use strict";
var __assign =
	(this && this.__assign) ||
	function () {
		__assign =
			Object.assign ||
			function (t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalThemeProvider = exports.getFontFromTheme = exports.Fonts = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var styled_components_1 = require("styled-components");
var useLocalTheme_1 = require("../styleguide/useLocalTheme");
exports.Fonts = {
	Inter: {
		links: ["https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap"],
		fontFamily: "'Inter', sans-serif;",
	},
	Mulish: {
		links: ["https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;700&display=swap"],
		fontFamily: "'Mulish', sans-serif;",
	},
	Titillium: {
		links: ["https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap"],
		fontFamily: "'Titillium Web', sans-serif;",
	},
};
var getFontFromTheme = function (theme) {
	if (theme && theme.font && exports.Fonts[theme.font]) {
		return exports.Fonts[theme.font];
	}
	return {
		fontFamily: "sans-serif",
		links: [],
	};
};
exports.getFontFromTheme = getFontFromTheme;
var GlobalThemeProvider = function (_a) {
	var defaultTheme = _a.defaultTheme,
		children = _a.children;
	var theme = (0, useLocalTheme_1.useLocalTheme)()[0];
	var font = exports.Fonts[theme.font];
	return (0, jsx_runtime_1.jsxs)(
		styled_components_1.ThemeProvider,
		__assign(
			{ theme: defaultTheme !== null && defaultTheme !== void 0 ? defaultTheme : theme },
			{
				children: [
					font &&
						font.links.map(function (link) {
							return (0, jsx_runtime_1.jsx)("link", { rel: "stylesheet", href: link }, link);
						}),
					children,
				],
			}
		)
	);
};
exports.GlobalThemeProvider = GlobalThemeProvider;
