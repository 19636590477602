import React from "react";
import { API } from "@escolalms/sdk/lib";
import { useCourseAgendaContext } from "./context";
import { CourseAgendaLesson } from "./CourseAgendaLesson";
import { Accordion } from "@/components/Accordion";

interface Props {
	lessons: API.Lesson[];
	depth?: number;
	mainLessonIndex?: number;
}

export const RecursiveLessons: React.FC<Props> = ({ lessons, depth = 0, mainLessonIndex }) => {
	const { currentLessonParentLessonsIds, currentLesson } = useCourseAgendaContext();

	return (
		<Accordion type="multiple" className={depth > 0 ? "space-y-4 divide-y divide-default-200" : "space-y-4"}>
			{lessons.map((l, index) => (
				<CourseAgendaLesson
					key={l.id}
					defaultOpen={currentLessonParentLessonsIds.includes(l.id) || currentLesson?.id === l.id}
					index={index}
					lesson={l}
					isSubLesson={depth > 0}
					mainLessonIndex={mainLessonIndex}
				>
					<RecursiveLessons lessons={l.lessons ?? []} depth={depth + 1} mainLessonIndex={index} />
				</CourseAgendaLesson>
			))}
		</Accordion>
	);
};
