import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Lesson } from "@escolalms/sdk/lib/types/api";

import { useCourseAgendaContext } from "../context";
import CourseAgendaTopic from "../CourseAgendaTopic";
import { Header } from "./Header";
import { LockedOverlay } from "./LockedOverlay";
import { StyledLessonItem } from "./styles";
import { AccordionContent, AccordionItem, AccordionTrigger } from "@/components/Accordion";

interface CourseAgendaLessonProps {
	children: React.ReactNode;
	lesson: Lesson;
	index: number;
	isSubLesson: boolean;
	defaultOpen?: boolean;
	mainLessonIndex?: number;
}

export const CourseAgendaLesson: React.FC<CourseAgendaLessonProps> = ({
	children,
	defaultOpen = false,
	index,
	lesson,
	isSubLesson,
	mainLessonIndex,
}) => {
	const { t } = useTranslation();
	const { areAllTopicsUnlocked, lockedLessonsIds, lockedTopicsIds } = useCourseAgendaContext();

	const [open, setOpen] = useState(defaultOpen);
	const openMenu = useCallback(() => setOpen(true), []);
	const toggleMenu = useCallback(() => setOpen((prev) => !prev), []);

	useEffect(() => {
		if (defaultOpen && !open) {
			setOpen(true);
		}
	}, [defaultOpen]);

	const isLessonLocked = useMemo(() => lockedLessonsIds.includes(lesson.id), [lesson.id, lockedLessonsIds]);

	const lessonHasLockedTopic = useMemo(
		() => lesson?.topics?.some((l) => lockedTopicsIds.includes(l.id)) ?? false,
		[lesson?.topics, lockedTopicsIds],
	);

	return (
		<AccordionItem
			value={JSON.stringify(lesson.id)}
			className={isSubLesson ? "border-0 pl-4" : "rounded-lg border-2 border-default-200 shadow"}
			// className={`lesson__item ${open ? "open" : "closed"} ${
			// 	isSubLesson ? "sub-lesson" : ""
			// }`}
		>
			{/*<Header*/}
			{/*	lesson={lesson}*/}
			{/*	index={index}*/}
			{/*	open={open}*/}
			{/*	onClick={openMenu}*/}
			{/*	onToggleClick={toggleMenu}*/}
			{/*	isSubLesson={isSubLesson}*/}
			{/*/>*/}
			<AccordionTrigger
				className={`text-left font-bold text-default-800 hover:no-underline ${
					isSubLesson ? "p-4 pb-0 pl-0" : "p-6"
				}`}
			>
				{lesson.title}
			</AccordionTrigger>
			<AccordionContent
				className={`relative grid divide-y divide-default-200 border-t border-default-200 ${
					isSubLesson ? "mt-4 pt-6" : "p-6"
				}`}
			>
				{(lessonHasLockedTopic || isLessonLocked) && !areAllTopicsUnlocked && (
					<LockedOverlay lesson={lesson} isLessonLocked={isLessonLocked} />
				)}
				{(lesson?.topics ?? []).map((topic, topicIndex) => (
					<CourseAgendaTopic
						key={topicIndex}
						clickable={
							areAllTopicsUnlocked ||
							(!areAllTopicsUnlocked && open && !lockedTopicsIds.includes(topic.id))
						}
						topic={topic}
						index={topicIndex + 1}
					/>
				))}
				{!!lesson.lessons?.length && children}
			</AccordionContent>
		</AccordionItem>
	);
};

export default CourseAgendaLesson;
