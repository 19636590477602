"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFontSizeByHeaderLevel = void 0;
var setFontSizeByHeaderLevel = function (level, mobile) {
	switch (level) {
		case 1:
			return mobile ? "20px" : "50px";
		case 2:
			return mobile ? "20px" : "40px";
		case 3:
			return mobile ? "20px" : "36px";
		case 4:
			return "20px";
		default:
			return "16px";
	}
};
exports.setFontSizeByHeaderLevel = setFontSizeByHeaderLevel;
