import BrandSeduc from "../../../images/vidaboa-seduc-brand.png";

const ImageBrandSeduc = () => {
	return <img src={BrandSeduc} alt="Seduc" className="block h-20 md:h-28" />;
};

const Footer = () => {
	return (
		<>
			<div className="bg-default-50 pb-6 pt-8 md:py-10">
				<div className="container mx-auto">
					<div className="flex flex-col items-center justify-between md:flex-row">
						<p className="text-sm text-default-600 md:text-base">
							© 2024. Vidaboa.fit - Todos os direitos reservados.
						</p>
						<ImageBrandSeduc />
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
