import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { withTheme } from "styled-components";

import { ExtendableStyledComponent } from "../../../types/component";
import { IconTitle, ProgressRing, Text, Icon } from "@/components/VB/";
import { RecursiveLessons } from "./_components/RecursiveLessons";
import {
	CourseAgendaContextProvider,
	CourseAgendaContextProviderProps,
	useCourseAgendaContext,
} from "./_components/context";
import { Progress } from "@/components/Progress";
import { API } from "@escolalms/sdk/lib";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import { IconFileText } from "@tabler/icons-react";

interface CourseAgendaContentProps extends ExtendableStyledComponent {
	mobile?: boolean;
}

type CourseAgendaProps = CourseAgendaContentProps & Omit<CourseAgendaContextProviderProps, "children">;

const StyledSection = styled("section")`
	width: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	& > header {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: flex-start;
		align-content: flex-start;
		margin-bottom: 20px;

		.lms-icon-title {
			margin: 0;
			flex-wrap: nowrap;
		}

		& > div {
			display: inline-flex;
			align-items: center;

			p {
				margin-right: 6px;
			}
		}
	}

	& > .lessons__list {
		padding-left: 0;
		list-style: none;
	}
`;

const CourseAgendaContent: React.FC<CourseAgendaContentProps> = () => {
	const { flatTopics, lessons, percentage, finishedTopicIds } = useCourseAgendaContext();

	return (
		<>
			<div className="mb-8 w-full rounded-3xl border-2 border-default-200 p-6 shadow">
				<div className="mb-4 flex items-center gap-1">
					<IconFileText size={20} className="text-default-600" />
					<span className="text-sm font-semibold">{flatTopics.length} Itens</span>
				</div>
				<div className="space-y-1">
					<Progress value={percentage} />
					<span className="text-sm font-semibold" title={`Resumo ${percentage}%`}>
						Finalizado {finishedTopicIds?.length ?? 0} de {flatTopics.length} Itens
					</span>
				</div>
			</div>
			<RecursiveLessons lessons={lessons} />
		</>
	);
};

export const CourseAgenda: React.FC<CourseAgendaProps> = ({ className, mobile, ...contextProps }) => (
	<CourseAgendaContextProvider {...contextProps}>
		<CourseAgendaContent className={className} mobile={mobile} />
	</CourseAgendaContextProvider>
);

const NewComponent = styled(CourseAgenda)<CourseAgendaProps>``;

export default withTheme(NewComponent);
