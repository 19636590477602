import * as React from "react";
import styled, { withTheme } from "styled-components";
import { useMemo, useCallback } from "react";
import { ExtendableStyledComponent } from "../../../types/component";

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">, ExtendableStyledComponent {
	label?: string | React.ReactNode;
	helper?: React.ReactNode;
	error?: string | React.ReactNode;
	container?: React.HTMLAttributes<HTMLDivElement>;
	type: "email" | "number" | "password" | "search" | "tel" | "text" | "url";
}

const notInputProps = {
	theme: undefined,
	container: undefined,
	label: undefined,
	helper: undefined,
	error: undefined,
};

export const Input: React.FC<InputProps> = (props) => {
	const { label, helper, container, error, required, className = "" } = props;

	const generateRandomInputId = useMemo(() => {
		const randomString = (Math.random() + 1).toString(36).substring(3);
		return `lms-input-id-${randomString}`;
	}, []);

	const addFilledClass = useCallback(() => {
		const { value, placeholder } = props;
		if ((value && value !== "") || (placeholder && placeholder !== "")) {
			return "filled";
		}
		return "";
	}, [props.value, props.placeholder]);

	const renderLabel = useCallback(() => {
		if (label) {
			return (
				<label htmlFor={generateRandomInputId}>
					{label}
					{required && <span className="required">*</span>}
				</label>
			);
		}
		return <></>;
	}, [generateRandomInputId, label, required]);

	return (
		<div className="mb-4">
			<div className="">
				<label className="mx-2 mb-1 inline-block font-semibold">
					{renderLabel()}
					{required ? "<span>*</span>" : ""}
				</label>
				<input {...props} {...notInputProps} id={label ? generateRandomInputId : undefined} className={`h-14 w-full rounded-md border-2 border-default-800 px-2 focus:outline-none focus:ring-4 focus:ring-default-200 ${error ? "border-danger-500 focus:ring-danger-200" : ""}`} />
			</div>
			{helper && <span className="mx-2 mt-1 text-sm text-default-600">{helper}</span>}
			{error && <div className="mx-2 mt-1 text-sm text-danger-500">{error}</div>}
		</div>
	);
};

const NewInput = styled(Input)<InputProps>``;

export default withTheme(NewInput);
