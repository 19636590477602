import * as React from "react";

import styled, { withTheme, ThemeContext } from "styled-components";
import { PropsWithChildren } from "react";

import Spin from "../Spin/Spin";
import { ExtendableStyledComponent } from "../../../types/component";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, ExtendableStyledComponent {
	children?: React.ReactNode;
	invert?: boolean;
	loading?: boolean;
	block?: boolean;
	className?: string;
	mode?: "primary" | "secondary" | "outline" | "white" | "icon";
	as?: React.ElementType;
	"aria-label"?: string;
}

const StyledButton = styled("button")<ButtonProps>``;

export const Button: React.FC<PropsWithChildren<ButtonProps>> = ({ as, children, mode = "primary", invert, loading = false, block = false, className = "", ...props }) => {
	return (
		<StyledButton
			invert={invert}
			as={as ?? "button"}
			mode={mode}
			loading={loading}
			block={block}
			{...props}
			className={`flex h-14 items-center justify-center gap-2 rounded-md font-semibold transition-all duration-300 ease-in-out disabled:cursor-default disabled:opacity-80 
			${mode === "primary" ? `bg-primary-999 text-default-0 hover:bg-primary-900` : ``}
			${mode === "secondary" ? `bg-default-800 text-default-0 hover:bg-default-700` : ``}
			${mode === "outline" ? `border-2 border-primary-999 text-primary-999 hover:bg-primary-999 hover:text-default-0` : ""}
			${block ? `w-full` : `w-fit px-6`}
			${className}`}
		>
			{loading && <Spin />}
			{children}
		</StyledButton>
	);
};

export default withTheme(styled(Button)<{ mode: string }>``);
