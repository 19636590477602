import * as React from "react";
import { Avatar, AvatarProps } from "@/components/VB/components/atoms/Avatar/Avatar";
import { RatingProps, Rating } from "../../atoms/Rating/Rating";
import { ReactNode } from "react";
import { ExtendableStyledComponent } from "../../../types/component";

interface StyledTourProps {
	mobile?: boolean;
}

export interface TutorProps extends StyledTourProps, ExtendableStyledComponent {
	title: ReactNode;
	fullName: ReactNode | string;
	avatar: AvatarProps;
	rating: RatingProps;
	description: ReactNode | string;
	coursesInfo?: ReactNode | string;
}

export const Tutor: React.FC<TutorProps> = (props) => {
	const { title, fullName, avatar, rating, coursesInfo, description, mobile, className = "" } = props;
	return (
		<>
			{React.isValidElement(title) ? (
				title
			) : (
				<h3 className="mb-4 text-2xl font-semibold text-primary-999">{title}</h3>
			)}
			<div className="flex">
				<div className="flex flex-col items-center">
					<Avatar {...avatar} className="mb-4" />
					<h4 className="text-center font-bold text-default-800">{fullName}</h4>
					<span className="text-xs font-extrabold text-default-600">{coursesInfo}</span>
				</div>
			</div>
			{/*<div className="avatar-row">*/}
			{/*	<Avatar size={"extraLarge"} {...avatar} />*/}
			{/*	<div className="avatar-info">*/}
			{/*		<Title as="h4" level={4}>*/}
			{/*			{fullName}*/}
			{/*		</Title>*/}
			{/*		<div className="ranking-row">*/}
			{/*			<Rating {...rating} label={rating.ratingValue} />*/}
			{/*			{coursesInfo && <Text className="course-info">{coursesInfo}</Text>}*/}
			{/*		</div>*/}
			{/*		{!mobile && <Text className="description">{description}</Text>}*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*{mobile && <Text className="description">{description}</Text>}*/}
		</>
	);
};
