import { Formik, FormikProps } from "formik";
import { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import type { DefaultResponseError, DefaultResponse } from "@escolalms/sdk/lib/types/api";
import type { ResponseError } from "umi-request";

import { Input, Button, Title, Link, Text, Checkbox } from "@/components/VB/";
import { ExtendableStyledComponent } from "../../../types/component";

interface MyFormValues {
	email: string;
	password: string;
	remember_me: boolean;
	error?: string;
}

interface Props extends ExtendableStyledComponent {
	onError?: (err: DefaultResponse<DefaultResponseError>) => void;
	onSuccess?: () => void;
	onResetPasswordLink?: () => void;
	onRegisterLink?: () => void;
	mobile?: boolean;
}

export const LoginForm: React.FC<Props> = ({ onSuccess, onError, onResetPasswordLink, onRegisterLink }) => {
	const initialValues: MyFormValues = {
		email: "",
		password: "",
		remember_me: false,
	};
	const { t } = useTranslation();
	const { login, user } = useContext(EscolaLMSContext);

	const formikRef = useRef<FormikProps<MyFormValues>>(null);

	useEffect(() => {
		if (user.error) {
			formikRef.current?.setErrors({
				// WTF. Error from the API is not consisted with rest of the responses
				// @ts-ignore
				error: user.error?.data?.message || user.error?.message,
				...user.error.errors,
			});
			onError?.(user.error);
		} else {
			formikRef.current?.setErrors({});
		}
	}, [user.error]);

	useEffect(() => {
		if (user.value) {
			onSuccess?.();
		}
	}, [user.value, onSuccess]);

	return (
		<>
			<Formik
				innerRef={formikRef}
				initialValues={initialValues}
				validate={(values) => {
					const errors: Partial<MyFormValues> = {};

					if (!values.email) {
						errors.email = t("Required");
					}

					if (!values.password) {
						errors.password = t("Required");
					}

					return errors;
				}}
				onSubmit={(values, { setSubmitting, setErrors }) => {
					login({
						...values,
						remember_me: values.remember_me ? 1 : 0,
					})
						.finally(() => setSubmitting(false))
						.catch((err: ResponseError<DefaultResponseError>) => {
							setErrors({ error: err.data?.message, ...err.data.errors });
							onError?.(err.data);
						})
						.finally(() => setSubmitting(false));
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						{errors && errors.error && <Text type="danger">{errors.error}</Text>}

						<Input type="email" name="email" label="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} error={touched.email && errors.email} />

						<Input type="password" name="password" label={t<string>("Password")} onChange={handleChange} onBlur={handleBlur} value={values.password} error={touched.password && errors.password} />

						<div className="mb-8 flex justify-between">
							<Checkbox name="remember_me" label={t<string>("Login.RememberMe")} value={String(values.remember_me)} checked={values.remember_me} onChange={handleChange} />

							<Link onClick={() => onResetPasswordLink && onResetPasswordLink()}>
								<span className="text-base font-semibold">{t<string>("Login.NotRemember")}</span>
							</Link>
						</div>

						<Button mode="primary" type="submit" loading={isSubmitting || user.loading} block>
							{t<string>("Login.Signin")}
						</Button>
					</form>
				)}
			</Formik>

			<div className="mt-8 flex items-center justify-center gap-2 text-base font-semibold">
				{t<string>("Login.NoAccount")}{" "}
				<Link onClick={() => onRegisterLink && onRegisterLink()}>
					<span className="text-base font-semibold">{t<string>("Login.Signup")}</span>
				</Link>
			</div>
		</>
	);
};

export default LoginForm;
