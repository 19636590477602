"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sharedTheme = void 0;
exports.sharedTheme = {
	buttonRadius: 0,
	checkboxRadius: 0,
	inputRadius: 0,
	cardRadius: 0,
	dm__background: "#000000",
	background: "#FFFFFF",
	cardBackgroundColor: "#F2F2F2",
	dm__cardBackgroundColor: "#4A4A4A",
	errorColor: "#EB5757",
	invertColor: "#FFCC00",
	white: "#FFFFFF",
	gray5: "#F8F8F8",
	gray4: "#F2F2F2",
	gray3: "#BDBDBD",
	gray2: "#6D6D6D",
	gray1: "#4A4A4A",
	black: "#000000",
	positive: "#6FC988",
};
