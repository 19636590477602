"use strict";
var __assign =
	(this && this.__assign) ||
	function () {
		__assign =
			Object.assign ||
			function (t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.contrastTheme = void 0;
var chroma_js_1 = require("chroma-js");
var shared_1 = require("./shared");
exports.contrastTheme = __assign(__assign({}, shared_1.sharedTheme), {
	primaryColor: "#157493",
	dm__primaryColor: "#03a9f4",
	dm__primaryColorOnLight: "#75c2ff",
	colorBackground: "#0073cf",
	dm__colorBackground: "#0174cf",
	secondaryColor: (0, chroma_js_1.blend)("#56CCF2", "#BDBDBD", "multiply").hex(),
	font: "Mulish",
	headerColor: "#111111",
	dm__background: "#232225",
	background: "#F2F2F2",
	gray2: "#2d2c2c",
	cardBackgroundColor: shared_1.sharedTheme.gray5,
	dm__cardBackgroundColor: "#202020",
	invertColor: "#23298e",
	dm__textColor: "#FFF",
	textColor: "#000",
	errorColor: "#b30000",
	dm__errorColor: "#ff6a6a",
	inputBg: shared_1.sharedTheme.gray5,
	dm__inputBg: "#333",
	inputDisabledBg: "#1f1f1f",
	dm__inputDisabledBg: "#1f1f1f",
	labelListValueColor: "#157493",
	dm__labelListValueColor: "#ff0000",
	primaryButtonDisabled: "#8ba9b3",
	outlineButtonColor: "#000000",
	outlineButtonInvertColor: "#23298e",
	dm__outlineButtonColor: "#FFFFFF",
	dm__outlineButtonInvertColor: "#ff9800",
	breadcrumbsColor: "#000000",
	dm__breadcrumbsColor: "#FFFFFF",
});
exports.default = exports.contrastTheme;
