import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { API } from "@escolalms/sdk/lib";

import { Button } from "../../../atoms/Button/Button";
import { Icon } from "../../../atoms/Icon/Icon";
import { useCourseAgendaContext } from "./context";
import { CurrentIcon, PendingIcon } from "./Icons";
import { TopicIcon } from "@/components/VB/components/organisms/CourseProgram/_components/TopicIcon";

export interface CourseAgendaTopicProps {
	index: number;
	topic: API.Topic;
	clickable: boolean;
}

type IconState = "pending" | "finished" | "current";

const CourseAgendaTopic: React.FC<CourseAgendaTopicProps> = ({ index, topic, clickable }) => {
	const { t } = useTranslation();
	const {
		onMarkFinished,
		onTopicClick,
		onNextTopicClick,
		onCourseFinished,
		finishedTopicIds,
		currentNotLockedTopicId,
		flatTopics,
		notFinishedTopics,
	} = useCourseAgendaContext();

	const isFinished = useMemo(() => finishedTopicIds.includes(topic.id), [finishedTopicIds, topic.id]);
	const isCurrent = useMemo(() => currentNotLockedTopicId === topic.id, [currentNotLockedTopicId, topic.id]);

	const iconState: IconState = useMemo(() => {
		if (isCurrent) {
			return "current";
		}
		if (isFinished) {
			return "finished";
		}
		return "pending";
	}, [isFinished, isCurrent]);

	const onClick = useCallback(() => {
		if (isCurrent) return;

		onTopicClick?.(topic);
	}, [isCurrent]);

	const isLastTopic = useMemo(() => flatTopics.at(-1)?.id === topic.id, [flatTopics, topic.id]);

	const isOneTopicLeft = useMemo(() => notFinishedTopics.length === 1, [flatTopics, finishedTopicIds]);

	return (
		<div className="space-y-4 py-4 first:pt-0 last:pb-0">
			<div
				className="flex w-full items-center gap-1"
				tabIndex={clickable ? 0 : -1}
				onClick={() => clickable && onClick()}
				onKeyDown={(e) => clickable && e.key === "Enter" && onClick()}
				role="button"
			>
				<TopicIcon type={topic.topicable_type} />
				<span className="text-sm font-bold">{topic.title}</span>
				{iconState === "finished" ? (
					<span className="ml-auto text-sm font-bold text-[#90D33E]">Assistida</span>
				) : null}
			</div>

			{isCurrent && !isFinished && isOneTopicLeft && (
				<Button
					block
					mode="primary"
					onClick={() => {
						if (!clickable) return;
						onMarkFinished?.(topic);
						onCourseFinished?.();
					}}
				>
					{t<string>("Course.finishCourse")}
				</Button>
			)}

			{isCurrent && !isFinished && !isOneTopicLeft && (
				<Button block mode="primary" onClick={() => clickable && onMarkFinished?.(topic)}>
					{t<string>("Course.markAsFinished")}
				</Button>
			)}

			{isCurrent && isFinished && !isLastTopic && (
				<Button block mode="primary" onClick={onNextTopicClick}>
					{t<string>("Course.nextTopic")}
				</Button>
			)}
		</div>
	);
};

export default CourseAgendaTopic;
