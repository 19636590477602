import * as React from "react";
import * as ProgressPrimitive from "@radix-ui/react-progress";

import { cn } from "@/utils/cn";

const Progress = React.forwardRef<React.ElementRef<typeof ProgressPrimitive.Root>, React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>>(({ className, value, ...props }, ref) => (
	<ProgressPrimitive.Root ref={ref} className={cn("relative h-[11px] w-full overflow-hidden rounded bg-primary-50 p-[2px]", className)} {...props}>
		<ProgressPrimitive.Indicator className="h-full flex-1 rounded bg-[#90D33E] transition-all" style={{ width: `${value}%` }} />
	</ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
