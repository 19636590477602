"use strict";
var __assign =
	(this && this.__assign) ||
	function () {
		__assign =
			Object.assign ||
			function (t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
var __createBinding =
	(this && this.__createBinding) ||
	(Object.create
		? function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				var desc = Object.getOwnPropertyDescriptor(m, k);
				if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
					desc = {
						enumerable: true,
						get: function () {
							return m[k];
						},
					};
				}
				Object.defineProperty(o, k2, desc);
			}
		: function (o, m, k, k2) {
				if (k2 === undefined) k2 = k;
				o[k2] = m[k];
			});
var __setModuleDefault =
	(this && this.__setModuleDefault) ||
	(Object.create
		? function (o, v) {
				Object.defineProperty(o, "default", { enumerable: true, value: v });
			}
		: function (o, v) {
				o["default"] = v;
			});
var __importStar =
	(this && this.__importStar) ||
	function (mod) {
		if (mod && mod.__esModule) return mod;
		var result = {};
		if (mod != null)
			for (var k in mod)
				if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
		__setModuleDefault(result, mod);
		return result;
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.blueTheme = void 0;
var chroma_js_1 = __importStar(require("chroma-js"));
var shared_1 = require("./shared");
exports.blueTheme = __assign(__assign({}, shared_1.sharedTheme), {
	font: "Mulish",
	primaryColor: "#56CCF2",
	dm__primaryColor: "#56CCF2",
	secondaryColor: (0, chroma_js_1.blend)("#56CCF2", "#BDBDBD", "multiply").hex(),
	dm__secondaryColor: (0, chroma_js_1.blend)("#56CCF2", "#BDBDBD", "multiply").hex(),
	headerColor: "#111111",
	background: "#F2F2F2",
	dm__background: "#232225",
	colorBackground: "#56CCF2",
	dm__colorBackground: "#56CCF2",
	cardBackgroundColor: shared_1.sharedTheme.gray5,
	dm__cardBackgroundColor: shared_1.sharedTheme.gray1,
	primaryButtonDisabled: "rgba(".concat(
		(0, chroma_js_1.default)(shared_1.sharedTheme.gray1).rgb().join(","),
		", 0.2)"
	),
	dm__primaryButtonDisabled: "rgba(".concat(
		(0, chroma_js_1.default)(shared_1.sharedTheme.gray1).rgb().join(","),
		", 0.2)"
	),
	textColor: "#000",
	dm__textColor: "#FFF",
	labelListValueColor: "#56CCF2",
	outlineButtonInvertColor: "#23298e",
	dm__outlineButtonInvertColor: "#ffcc00",
});
exports.default = exports.blueTheme;
