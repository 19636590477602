import React, { useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
// import GoTop from "@/components/_App/GoTop";
import { ToastContainer } from "react-toastify";
import Navbar from "./Navbar";
import Footer from "./Footer";
import "react-toastify/dist/ReactToastify.css";
import { setConfiguration } from "react-grid-system";
import Warning from "./Warning";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";

declare global {
	interface Window {
		ybug_settings: Ybug;
		REACT_APP_YBUG_ID: string;
	}
}

interface Ybug {
	id: string;
}
window.ybug_settings = window.ybug_settings || {};

const YBUG_ID = window.REACT_APP_YBUG_ID || (process && process.env && process.env.REACT_APP_YBUG_ID);

setConfiguration({ maxScreenClass: "xl" });

const Layout: React.FC<{
	children: React.ReactNode;
	metaTitle?: string | undefined;
}> = ({ children, metaTitle }) => {
	const { pathname } = useLocation();

	const { fetchConfig, fetchSettings } = useContext(EscolaLMSContext);

	useEffect(() => {
		fetchSettings();
		fetchConfig();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// ybug
		(function () {
			///
			if (window && YBUG_ID) {
				try {
					window.ybug_settings = { id: YBUG_ID };
					const ybug = document.createElement("script");
					ybug.type = "text/javascript";
					ybug.async = true;
					ybug.src = "https://widget.ybug.io/button/" + window.ybug_settings.id + ".js";
					const s = document.getElementsByTagName("script")[0];
					s && s.parentNode && s.parentNode.insertBefore(ybug, s);
				} catch (er) {}
			}
		})();
	}, []);

	const isLogin = pathname.includes("/login");
	const isCourse = pathname.includes("/course/");

	return (
		<React.Fragment>
			<Helmet>
				<title>
					{metaTitle
						? `${metaTitle} | Vidaboa.fit - Nutrição, Atividade Física, Desenvolvimento Pessoal, Saúde Mental`
						: "Vidaboa.fit - Nutrição, Atividade Física, Desenvolvimento Pessoal, Saúde Mental"}
				</title>
				<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				<meta
					name="description"
					content="Vidaboa.fit - Nutrição, Atividade Física, Desenvolvimento Pessoal, Saúde Mental"
				/>
				<meta
					name="og:title"
					property="og:title"
					content="Vidaboa.fit - Nutrição, Atividade Física, Desenvolvimento Pessoal, Saúde Mental"
				></meta>
				<meta
					name="twitter:card"
					content="Vidaboa.fit - Nutrição, Atividade Física, Desenvolvimento Pessoal, Saúde Mental"
				></meta>
				<link rel="shortcut icon" href="./favicon.ico" type="image/x-icon" />
				<link rel="apple-touch-icon" sizes="180x180" href="./apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="./favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="./favicon-16x16.png" />
			</Helmet>
			<ToastContainer hideProgressBar position="top-center" theme="colored" />
			<div className="site-wrapper">
				{!isLogin && <Navbar />}
				{children}
				{!isLogin && <Footer />}
				{localStorage.getItem("hideWarning") !== "true" && <Warning />}
			</div>
			{/* <GoTop scrollStepInPx="100" delayInMs={10} /> */}
		</React.Fragment>
	);
};

export default Layout;
