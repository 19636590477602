"use strict";
var __assign =
	(this && this.__assign) ||
	function () {
		__assign =
			Object.assign ||
			function (t) {
				for (var s, i = 1, n = arguments.length; i < n; i++) {
					s = arguments[i];
					for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
				}
				return t;
			};
		return __assign.apply(this, arguments);
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.redTheme = void 0;
var chroma_js_1 = require("chroma-js");
var shared_1 = require("./shared");
exports.redTheme = __assign(__assign({}, shared_1.sharedTheme), {
	font: "Titillium",
	primaryColor: "#E60037",
	dm__primaryColor: "#E60037",
	secondaryColor: (0, chroma_js_1.blend)("#E60037", "#BDBDBD", "multiply").hex(),
	dm__secondaryColor: (0, chroma_js_1.blend)("#E60037", "#BDBDBD", "multiply").hex(),
	headerColor: "#111111",
	background: "#F2F2F2",
	dm__background: "#232225",
	colorBackground: "#E60037",
	dm__colorBackground: "#E60037",
	cardBackgroundColor: shared_1.sharedTheme.gray5,
	dm__cardBackgroundColor: shared_1.sharedTheme.gray1,
	dm__textColor: "#FFF",
	textColor: "#000",
});
exports.default = exports.redTheme;
