"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useAutosizeTextArea = function (textAreaRef, value) {
	(0, react_1.useEffect)(
		function () {
			if (textAreaRef) {
				textAreaRef.style.height = "0px";
				var scrollHeight = textAreaRef.scrollHeight;
				textAreaRef.style.height = scrollHeight + "px";
			}
		},
		[textAreaRef, value]
	);
};
exports.default = useAutosizeTextArea;
