import * as React from "react";

export interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const Avatar: React.FC<AvatarProps> = (props) => {
	const { className = "" } = props;
	return <img alt="" {...props} className={`h-20 w-20 rounded-full object-cover ${className}`} />;
};

export default Avatar;
