import React, { ReactNode, MouseEvent, useRef } from "react";
import Spin from "../../atoms/Spin/Spin";
import { IconSearch } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "@/components/VB/hooks/useOnClickOutside";

export interface SearchProps {
	className?: string;
	placeholder: string;
	loading?: boolean;
	isSingle?: boolean;
	isFocused?: boolean;
	children?: ReactNode;
	onSearch?: (value: string) => void;
	onChange?: (value: string) => void;
	onSubmit?: (value: string) => void;
	filterOptions?: () => void;
}

export const Search: React.FC<SearchProps> = (props) => {
	const {
		className = "",
		placeholder,
		loading,
		isSingle,
		children,
		onSearch,
		onChange,
		onSubmit,
		filterOptions,
	} = props;

	const ref = useRef(null);
	const [value, setValue] = React.useState("");
	const [isFocused, setIsFocused] = React.useState(false);
	const childrenList = React.Children.toArray(children);
	const { t } = useTranslation();

	const toggleFocus = () => {
		setIsFocused((isFocused) => !isFocused);
	};

	const filterChildren = (
		childrenList: ReactNode[],
		filterOptions?: () => boolean,
		onChange?: (value: string) => void,
		toggleFocus?: () => void,
	) => {
		const handleChildClick = (e: MouseEvent, child: React.ReactElement) => {
			onChange && onChange(child.props.children as string);
			toggleFocus && toggleFocus();
			setValue(child.props.children as string);
			child.props.onClick && child.props.onClick(e);
		};

		return childrenList
			.filter((child) => {
				if (React.isValidElement(child)) {
					if (typeof filterOptions === "function") {
						return filterOptions();
					} else if (typeof child.props.children === "string") {
						return child.props.children
							.toLowerCase()
							.includes(value.toLowerCase());
					}
				}
				return false;
			})
			.map((child, index) => {
				if (React.isValidElement(child)) {
					return React.cloneElement(child, {
						key: index,
						// @ts-ignore
						onClick: (e: MouseEvent) => handleChildClick(e, child),
					});
				}
				return child;
			});
	};

	useOnClickOutside(ref, () => setIsFocused(false));

	return (
		<>
			<div className={`relative ${className}`} ref={ref}>
				<div className="relative">
					<input
						type="text"
						placeholder={placeholder}
						value={value}
						className="h-10 w-full bg-default-50 px-2"
						onFocus={() => toggleFocus()}
						onKeyDown={(e) =>
							e.key === "Enter" && onSubmit?.(value)
						}
						onChange={(e) => {
							const inputValue = e.target.value;
							setValue(inputValue);
							onSearch?.(inputValue);
						}}
					/>
					<button
						type="button"
						className="absolute right-0 top-0 flex h-10 w-10 items-center justify-center hover:bg-default-200"
						onClick={() => {
							onSubmit?.(value);
						}}
					>
						{loading ? <Spin /> : <IconSearch size={20} />}
					</button>
				</div>
				{isSingle ?? (
					<>
						<div
							className={`absolute h-auto max-h-[370px] w-full overflow-y-auto border border-default-200 bg-default-0 shadow-xl ${
								isFocused ? "block" : "hidden"
							}`}
						>
							<div className="p-2">
								{filterChildren(childrenList).length
									? filterChildren(childrenList)
									: t("Search.NoResults")}
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
};
