import React from "react";

const Chevron: React.FC = () => (
	<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5.88128 0.381282C6.22299 0.0395728 6.77701 0.0395728 7.11872 0.381282L12.3687 5.63128C12.7104 5.97299 12.7104 6.52701 12.3687 6.86872C12.027 7.21043 11.473 7.21043 11.1313 6.86872L6.5 2.23744L1.86872 6.86872C1.52701 7.21043 0.97299 7.21043 0.631282 6.86872C0.289573 6.52701 0.289573 5.97299 0.631282 5.63128L5.88128 0.381282Z"
			fill="currentColor"
		/>
	</svg>
);

const Program: React.FC = () => (
	<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M1.33333 19.1668H14.6667C14.8877 19.1668 15.0996 19.079 15.2559 18.9228C15.4122 18.7665 15.5 18.5545 15.5 18.3335V8.3335C15.5 8.11248 15.4122 7.90052 15.2559 7.74424C15.0996 7.58796 14.8877 7.50016 14.6667 7.50016H1.33333C1.11232 7.50016 0.900358 7.58796 0.744078 7.74424C0.587797 7.90052 0.5 8.11248 0.5 8.3335V18.3335C0.5 18.5545 0.587797 18.7665 0.744078 18.9228C0.900358 19.079 1.11232 19.1668 1.33333 19.1668ZM2.16667 9.16683H13.8333V17.5002H2.16667V9.16683ZM11.3333 12.5002V14.1668C11.3333 14.3878 11.2455 14.5998 11.0893 14.7561C10.933 14.9124 10.721 15.0002 10.5 15.0002H5.5C5.27899 15.0002 5.06702 14.9124 4.91074 14.7561C4.75446 14.5998 4.66667 14.3878 4.66667 14.1668V12.5002C4.66667 12.2791 4.75446 12.0672 4.91074 11.9109C5.06702 11.7546 5.27899 11.6668 5.5 11.6668C5.72101 11.6668 5.93297 11.7546 6.08926 11.9109C6.24554 12.0672 6.33333 12.2791 6.33333 12.5002V13.3335H9.66667V12.5002C9.66667 12.2791 9.75446 12.0672 9.91074 11.9109C10.067 11.7546 10.279 11.6668 10.5 11.6668C10.721 11.6668 10.933 11.7546 11.0893 11.9109C11.2455 12.0672 11.3333 12.2791 11.3333 12.5002ZM3 4.16683H13C13.221 4.16683 13.433 4.25463 13.5893 4.41091C13.7455 4.56719 13.8333 4.77915 13.8333 5.00016C13.8333 5.22118 13.7455 5.43314 13.5893 5.58942C13.433 5.7457 13.221 5.8335 13 5.8335H3C2.77899 5.8335 2.56702 5.7457 2.41074 5.58942C2.25446 5.43314 2.16667 5.22118 2.16667 5.00016C2.16667 4.77915 2.25446 4.56719 2.41074 4.41091C2.56702 4.25463 2.77899 4.16683 3 4.16683ZM4.66667 2.50016C4.44565 2.50016 4.23369 2.41237 4.07741 2.25609C3.92113 2.0998 3.83333 1.88784 3.83333 1.66683C3.83333 1.44582 3.92113 1.23385 4.07741 1.07757C4.23369 0.921293 4.44565 0.833496 4.66667 0.833496H11.3333C11.5543 0.833496 11.7663 0.921293 11.9226 1.07757C12.0789 1.23385 12.1667 1.44582 12.1667 1.66683C12.1667 1.88784 12.0789 2.0998 11.9226 2.25609C11.7663 2.41237 11.5543 2.50016 11.3333 2.50016H4.66667Z"
			fill="currentColor"
		/>
	</svg>
);

const Lock: React.FC = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<path d="M10.862 17.011l-1.862-1.837 1.129-1.13 1.859 1.827 1.838-1.871 1.139 1.139-1.833 1.86 1.868 1.836-1.138 1.14-1.862-1.836-1.835 1.861-1.13-1.129 1.827-1.86zm10.138-7.011v14h-18v-14h3v-4c0-3.313 2.687-6 6-6s6 2.687 6 6v4h3zm-13 0h8v-4c0-2.206-1.795-4-4-4s-4 1.794-4 4v4zm11 2h-14v10h14v-10z" />
	</svg>
);

const Finished: React.FC = () => (
	<svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M0.634003 3.95823C0.769077 3.82767 0.950483 3.75611 1.13832 3.7593C1.32615 3.76249 1.50502 3.84016 1.63559 3.97523L3.9065 6.32619L9.13046 1.16315C9.26663 1.04596 9.44225 0.984889 9.62175 0.992303C9.80125 0.999718 9.97123 1.07506 10.0973 1.20309C10.2233 1.33111 10.296 1.50224 10.3006 1.68184C10.3052 1.86143 10.2414 2.03607 10.1221 2.1704L4.391 7.83707C4.25895 7.96767 4.08089 8.04118 3.89517 8.04177H3.88879C3.79532 8.0412 3.70288 8.02213 3.6168 7.98565C3.53073 7.94918 3.45273 7.89604 3.38729 7.82928L0.617003 4.95982C0.486441 4.82474 0.414883 4.64334 0.418071 4.4555C0.421259 4.26767 0.498932 4.0888 0.634003 3.95823ZM13.3805 1.16315L8.15863 6.32619L7.97234 6.13282C7.90778 6.06553 7.83058 6.01166 7.74516 5.97428C7.65974 5.9369 7.56778 5.91675 7.47455 5.91499C7.38132 5.91324 7.28867 5.9299 7.2019 5.96404C7.11513 5.99817 7.03595 6.0491 6.96891 6.11391C6.90187 6.17871 6.84828 6.25611 6.81122 6.34168C6.77416 6.42724 6.75436 6.51927 6.75295 6.61251C6.75154 6.70574 6.76856 6.79833 6.80301 6.88497C6.83747 6.97161 6.8887 7.0506 6.95375 7.1174L7.638 7.82573C7.70344 7.89249 7.78144 7.94564 7.86751 7.98211C7.95358 8.01858 8.04602 8.03766 8.1395 8.03823H8.14729C8.33302 8.03764 8.51108 7.96413 8.64313 7.83352L14.3743 2.16686C14.491 2.03193 14.5525 1.85792 14.5465 1.6796C14.5406 1.50128 14.4675 1.33178 14.342 1.20499C14.2164 1.0782 14.0477 1.00346 13.8694 0.995704C13.6912 0.987946 13.5165 1.04775 13.3805 1.16315Z"
			fill="#6FCF97"
		/>
	</svg>
);

const EditAlt: React.FC = () => (
	<svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.078 0.539475C14.4686 0.148951 15.1017 0.148951 15.4923 0.539475L18.4923 3.53948C18.8828 3.93 18.8828 4.56316 18.4923 4.95369L9.49226 13.9537C9.30473 14.1412 9.05037 14.2466 8.78516 14.2466H5.78516C5.23287 14.2466 4.78516 13.7989 4.78516 13.2466V10.2466C4.78516 9.98137 4.89051 9.72701 5.07805 9.53948L14.078 0.539475ZM6.78516 10.6608V12.2466H8.37094L16.3709 4.24658L14.7852 2.6608L6.78516 10.6608ZM0.785156 4.24658C0.785156 3.14201 1.68059 2.24658 2.78516 2.24658H7.78516C8.33744 2.24658 8.78516 2.6943 8.78516 3.24658C8.78516 3.79887 8.33744 4.24658 7.78516 4.24658H2.78516V16.2466H14.7852V11.2466C14.7852 10.6943 15.2329 10.2466 15.7852 10.2466C16.3374 10.2466 16.7852 10.6943 16.7852 11.2466V16.2466C16.7852 17.3512 15.8897 18.2466 14.7852 18.2466H2.78516C1.68059 18.2466 0.785156 17.3512 0.785156 16.2466V4.24658Z"
			fill="currentColor"
		/>
	</svg>
);

const Edit: React.FC = () => (
	<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M14.078 0.539475C14.4686 0.148951 15.1017 0.148951 15.4923 0.539475L19.4923 4.53948C19.8828 4.93 19.8828 5.56316 19.4923 5.95369L6.49226 18.9537C6.30473 19.1412 6.05037 19.2466 5.78516 19.2466H1.78516C1.23287 19.2466 0.785156 18.7989 0.785156 18.2466V14.2466C0.785156 13.9814 0.890513 13.727 1.07805 13.5395L11.0778 3.53968L14.078 0.539475ZM11.7852 5.6608L2.78516 14.6608V17.2466H5.37094L14.3709 8.24658L11.7852 5.6608ZM15.7852 6.83237L17.3709 5.24658L14.7852 2.6608L13.1994 4.24658L15.7852 6.83237Z"
			fill="currentColor"
		/>
	</svg>
);

const EditAll: React.FC = () => (
	<svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m14.776 18.689 7.012-7.012c.133-.133.217-.329.217-.532 0-.179-.065-.363-.218-.515l-2.423-2.415c-.144-.143-.333-.215-.522-.215s-.378.072-.523.215l-7.027 6.996c-.442 1.371-1.158 3.586-1.265 3.952-.125.433.199.834.573.834.41 0 .696-.099 4.176-1.308zm-2.258-2.392 1.17 1.171c-.704.232-1.275.418-1.729.566zm.968-1.154 5.356-5.331 1.347 1.342-5.346 5.347zm-4.486-1.393c0-.402-.356-.75-.75-.75-2.561 0-2.939 0-5.5 0-.394 0-.75.348-.75.75s.356.75.75.75h5.5c.394 0 .75-.348.75-.75zm5-3c0-.402-.356-.75-.75-.75-2.561 0-7.939 0-10.5 0-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75zm0-3c0-.402-.356-.75-.75-.75-2.561 0-7.939 0-10.5 0-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75zm0-3c0-.402-.356-.75-.75-.75-2.561 0-7.939 0-10.5 0-.394 0-.75.348-.75.75s.356.75.75.75h10.5c.394 0 .75-.348.75-.75z"
			fill="currentColor"
		/>
	</svg>
);

const Copy: React.FC = () => (
	<svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill="currentColor"
			d="M1.5013 19.1668H9.83464C10.0556 19.1668 10.2676 19.079 10.4239 18.9228C10.5802 18.7665 10.668 18.5545 10.668 18.3335V8.3335C10.668 8.11248 10.5802 7.90052 10.4239 7.74424C10.2676 7.58796 10.0556 7.50016 9.83464 7.50016H1.5013C1.28029 7.50016 1.06833 7.58796 0.912046 7.74424C0.755766 7.90052 0.667969 8.11248 0.667969 8.3335V18.3335C0.667969 18.5545 0.755766 18.7665 0.912046 18.9228C1.06833 19.079 1.28029 19.1668 1.5013 19.1668ZM2.33464 9.16683H9.0013V17.5002H2.33464V9.16683ZM12.3346 15.0002V5.8335H4.83464C4.61362 5.8335 4.40166 5.7457 4.24538 5.58942C4.0891 5.43314 4.0013 5.22118 4.0013 5.00016C4.0013 4.77915 4.0891 4.56719 4.24538 4.41091C4.40166 4.25463 4.61362 4.16683 4.83464 4.16683H13.168C13.389 4.16683 13.6009 4.25463 13.7572 4.41091C13.9135 4.56719 14.0013 4.77915 14.0013 5.00016V15.0002C14.0013 15.2212 13.9135 15.4331 13.7572 15.5894C13.6009 15.7457 13.389 15.8335 13.168 15.8335C12.947 15.8335 12.735 15.7457 12.5787 15.5894C12.4224 15.4331 12.3346 15.2212 12.3346 15.0002ZM17.3346 1.66683V12.5002C17.3346 12.7212 17.2468 12.9331 17.0906 13.0894C16.9343 13.2457 16.7223 13.3335 16.5013 13.3335C16.2803 13.3335 16.0683 13.2457 15.912 13.0894C15.7558 12.9331 15.668 12.7212 15.668 12.5002V2.50016H8.16797C7.94696 2.50016 7.73499 2.41237 7.57871 2.25609C7.42243 2.0998 7.33464 1.88784 7.33464 1.66683C7.33464 1.44582 7.42243 1.23385 7.57871 1.07757C7.73499 0.921293 7.94696 0.833496 8.16797 0.833496H16.5013C16.7223 0.833496 16.9343 0.921293 17.0906 1.07757C17.2468 1.23385 17.3346 1.44582 17.3346 1.66683Z"
		/>
	</svg>
);

export const ICONS_DICTIONARY = {
	chevron: Chevron,
	program: Program,
	lock: Lock,
	finished: Finished,
	edit: Edit,
	editAll: EditAll,
	editAlt: EditAlt,
	copy: Copy,
} as const;
