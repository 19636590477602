import { Formik, FormikErrors } from "formik";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { EscolaLMSContext } from "@escolalms/sdk/lib/react";
import type { DefaultResponseError } from "@escolalms/sdk/lib/types/api";
import type { ResponseError } from "umi-request";

import styled, { withTheme } from "styled-components";

import { Input, Button, Link, Text } from "@/components/VB/";
import { ExtendableStyledComponent } from "../../../types/component";

interface MyFormValues {
	email: string;
	password: string;
	error?: string;
}

interface Props extends ExtendableStyledComponent {
	onFirstStepError?: (err: ResponseError<DefaultResponseError>) => void;
	onSecondStepError?: (err: ResponseError<DefaultResponseError>) => void;
	onFirstStepSuccess?: () => void;
	onSecondStepSuccess?: () => void;
	backToLogin?: () => void;
	onRegisterLink?: () => void;
	mobile?: boolean;
	return_url?: string;
	secondStep?: boolean;
	email?: string;
	token?: string;
}

export const ResetPasswordForm: React.FC<Props> = ({ onFirstStepError, onSecondStepError, onFirstStepSuccess, onSecondStepSuccess, backToLogin, onRegisterLink, return_url, secondStep, email, token }) => {
	const initialValues: MyFormValues = { email: "", password: "" };
	const { t } = useTranslation();
	const { forgot, reset } = useContext(EscolaLMSContext);

	const handleFirstStep = useCallback((values: MyFormValues, setSubmitting: (isSubmitting: boolean) => void, setErrors: (errors: FormikErrors<MyFormValues>) => void) => {
		forgot({
			email: values.email,
			return_url: `${window.location.origin}/${return_url}`,
		})
			.then(() => onFirstStepSuccess && onFirstStepSuccess())
			.catch((err: ResponseError<DefaultResponseError>) => {
				setErrors({ error: err.data.message, ...err.data.errors });
				onFirstStepError && onFirstStepError(err);
			})
			.finally(() => setSubmitting(false));
	}, []);

	const handleSecondStep = useCallback((values: MyFormValues, setSubmitting: (isSubmitting: boolean) => void, setErrors: (errors: FormikErrors<MyFormValues>) => void) => {
		reset({
			password: values.password,
			email: String(email),
			token: String(token),
		})
			.then(() => {
				onSecondStepSuccess && onSecondStepSuccess();
			})
			.catch((err: ResponseError<DefaultResponseError>) => {
				setErrors({ error: err.data.message, ...err.data.errors });
				onSecondStepError && onSecondStepError(err);
			})
			.finally(() => setSubmitting(false));
	}, []);

	return (
		<>
			<h4 className="mb-6 text-xl font-semibold">{t<string>("ResetForm.ResetPassword")}</h4>

			<Formik
				initialValues={initialValues}
				validate={(values) => {
					const errors: Partial<MyFormValues> = {};
					if (!values.email && !secondStep) {
						errors.email = t("Required");
					}
					return errors;
				}}
				onSubmit={(values, { setSubmitting, setErrors }) => {
					!secondStep ? handleFirstStep(values, setSubmitting, setErrors) : handleSecondStep(values, setSubmitting, setErrors);
				}}
			>
				{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
					<form onSubmit={handleSubmit}>
						{errors && errors.error && <Text type="danger">{errors.error}</Text>}
						{!secondStep ? <Input type="email" name="email" label={t<string>("Email")} onChange={handleChange} onBlur={handleBlur} value={values.email} error={touched.email && errors.email} /> : <Input type="password" name="password" label={t<string>("Password")} onChange={handleChange} onBlur={handleBlur} value={values.password} error={touched.password && errors.password} />}
						<Button mode="primary" type="submit" loading={isSubmitting} block>
							{t<string>("ResetForm.Reset")}
						</Button>
					</form>
				)}
			</Formik>

			{!secondStep && (
				<>
					<div className="mt-10 text-center">
						<Link onClick={() => backToLogin && backToLogin()}>
							<span className="text-base font-semibold">{t<string>("ResetForm.BackToLogin")}</span>
						</Link>
					</div>
					<div className="mt-4 flex items-center justify-center gap-2 text-base font-semibold">
						{t<string>("Login.NoAccount")}{" "}
						<Link onClick={() => onRegisterLink && onRegisterLink()}>
							<span className="text-base font-semibold">{t<string>("Login.Signup")}</span>
						</Link>
					</div>
				</>
			)}
		</>
	);
};

export default withTheme(styled(ResetPasswordForm)<{ mobile: boolean }>``);
