"use strict";
var __spreadArray =
	(this && this.__spreadArray) ||
	function (to, from, pack) {
		if (pack || arguments.length === 2)
			for (var i = 0, l = from.length, ar; i < l; i++) {
				if (ar || !(i in from)) {
					if (!ar) ar = Array.prototype.slice.call(from, 0, i);
					ar[i] = from[i];
				}
			}
		return to.concat(ar || Array.prototype.slice.call(from));
	};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLessonParentsIds = exports.getFlatLessons = exports.getFlatTopics = void 0;
function getFlatTopics(lessons) {
	return lessons.reduce(function (acc, l) {
		var _a, _b;
		return __spreadArray(
			__spreadArray(
				__spreadArray([], acc, true),
				(_a = l === null || l === void 0 ? void 0 : l.topics) !== null && _a !== void 0 ? _a : [],
				true
			),
			getFlatTopics((_b = l === null || l === void 0 ? void 0 : l.lessons) !== null && _b !== void 0 ? _b : []),
			true
		);
	}, []);
}
exports.getFlatTopics = getFlatTopics;
function getFlatLessons(lessons) {
	return lessons.reduce(function (acc, l) {
		var _a;
		return __spreadArray(
			__spreadArray(__spreadArray([], acc, true), [l], false),
			getFlatLessons((_a = l === null || l === void 0 ? void 0 : l.lessons) !== null && _a !== void 0 ? _a : []),
			true
		);
	}, []);
}
exports.getFlatLessons = getFlatLessons;
function getLessonParentsIds(flatLessons, lesson, result) {
	if (result === void 0) {
		result = [];
	}
	var parentsIds = __spreadArray([], result, true);
	var parentLesson = flatLessons.find(function (l) {
		var _a;
		return (_a = l === null || l === void 0 ? void 0 : l.lessons) === null || _a === void 0
			? void 0
			: _a.find(function (innerL) {
					return (
						(innerL === null || innerL === void 0 ? void 0 : innerL.id) ===
						(lesson === null || lesson === void 0 ? void 0 : lesson.id)
					);
				});
	});
	if (parentLesson) {
		parentsIds.unshift(parentLesson.id);
		return getLessonParentsIds(flatLessons, parentLesson, parentsIds);
	}
	return parentsIds;
}
exports.getLessonParentsIds = getLessonParentsIds;
